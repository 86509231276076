<script setup>
import { onClickOutside } from '@vueuse/core'
import { Swiper } from 'swiper'
import { COOKIE_KEYS } from './../utils/constants'
import 'swiper/css/bundle'
import rewriteImageUrl from '~/utils/rewrite-image-url';
// import { useUnleashStore } from '~/stores/unleash.store'
const { $get } = useApi()
const isHidden = ref(false)

onMounted(() => {
  const swiper = new Swiper('.swiperCategories', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination2',
      clickable: true
    }
  })
})

onMounted(() => {
  window.addEventListener('resize', onWindowResize)
  onWindowResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
})

const isShow = ref(false)
const refDropdown = ref(false)

onClickOutside(refDropdown, () => {
  isHidden.value = false
})

const length = ref(6)

const onWindowResize = () => {
  const width = window.innerWidth
  if (width > 1280) {
    length.value = 6
  } else if (width > 640) {
    length.value = 4
  } else {
    length.value = 1
  }
}

const { data: category } = await useAsyncData('get active categories', () =>
  $get('/api/v1/category?isActive=true&_sort=priority:asc')
)

const firstCategoryList = computed(() => category.value.slice(0, length.value))

const endCategoryList = computed(() => category.value.slice(length.value))

const authToken = useCookie(COOKIE_KEYS.authToken)

const userStore = useUserStore()

function onLogout() {
  userStore.clearUser()
  removeStoredAuthToken()
  authToken.value = ''
  router.push('/login')
}

// const unleashStore = useUnleashStore()
</script>

<template>
  <div class="w-full">
    <div class="container mx-auto md:mt-6 mb-5">
      <div class="px-4 xl:px-0 hidden lg:flex flex-row justify-between flex-start items-center">
        <nuxt-link to="/">
          <t-icon-logo class="w-40 h-7" />
        </nuxt-link>
        <div class="w-102 h-10 relative">
          <a-search-input />
        </div>
        <div class="flex flex-row space-x-8 items-center">
          <!-- <nuxt-link to="/" class="cursor-pointer">
            <t-icon-location />
          </nuxt-link> -->

          <!-- <template v-if="unleashStore.isAuthorizationEnable">
            <div v-if="userStore.user?.id" ref="refDropdown" class="cursor-pointer relative">
              <t-icon-profile @click="isHidden = !isHidden" />
              <div
                class="t-popup absolute z-100 bg-white top-15 right-0"
                :class="
                  isHidden
                    ? 'opacity-100 transition-all duration-300'
                    : 'opacity-0 transition-all duration-300'
                "
              >
                <div
                  class="relative flex flex-col gap-2 wrapper__dropdown min-w-44 max-w-50 overflow-hidden"
                >
                  <div class="text-xl font-sans font-semibold text-black font-sans">
                    {{ userStore.user?.name }}
                  </div>
                  <div class="bg-blue-400 h-[2px] w-full" />
                  <nuxt-link to="/user/orders" class="text-lg font-sans text-black font-sans">
                    Мои покупки
                  </nuxt-link>
                  <nuxt-link to="/user/favourites" class="text-lg font-sans text-black font-sans">
                    Избранное
                  </nuxt-link>
                  <nuxt-link
                    to="/login"
                    class="text-lg text-decorative-scarlet font-sans"
                    @click="onLogout()"
                  >
                    Выход
                  </nuxt-link>
                </div>
              </div>
            </div>
            <nuxt-link v-else to="/login" class="cursor-pointer">
              <t-button
                rounded="large"
                class="font-normal text-primary cursor-pointer"
                type="secondary"
              >
                Войти
              </t-button>
            </nuxt-link>
          </template> -->
        </div>
      </div>
      <div
        v-if="category && category.length"
        class="px-4 xl:px-0 hidden lg:flex flex-row justify-between mt-5 items-center"
      >
        <div v-for="(item, index) in firstCategoryList" :key="index" class="overflow-hidden">
          <nuxt-link
            :to="`/categories/${item.slug}`"
            exact-active-class="bg-gray-100"
            class="py-2 px-5 flex flex-row space-x-2 items-center justify-center rounded-full"
          >
            <div v-if="item.icon">
              <img :imgproxy-src="rewriteImageUrl(item.icon.uploadURL)" class="w-5 h-5" />
            </div>

            <div class="font-sf-pro text-lg">
              {{ item.title }}
            </div>
          </nuxt-link>
        </div>
        <div v-if="endCategoryList.length > 0" class="relative">
          <div class="flex-row space-x-2 relative flex" @click="isShow = !isShow">
            <t-icon-circle />
            <div class="font-sf-pro cursor-pointer text-xl">Еще</div>
          </div>

          <t-dropdown
            v-if="isShow"
            ref="refDropdown"
            :content="endCategoryList"
            class="absolute top-10 right-0 z-80"
          />
        </div>
      </div>
      <div class="block overflow-hidden lg:hidden">
        <div class="swiperCategories py-3 px-4 xl:px-0 overflow-hidden">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in category"
              :key="index"
              class="swiper-slide mr-3 !w-fit h-auto last:mr-0"
            >
              <nuxt-link
                :to="`/categories/${item.slug}`"
                exact-active-class="bg-white"
                responsive
                class="py-2 px-5 flex flex-row space-x-2 items-center justify-center rounded-full"
                style="box-shadow: 0px 2px 16px #00000024"
              >
                <div class="!w-5 !h-5">
                  <img :imgproxy-src="rewriteImageUrl(item.icon.uploadURL)" class="!w-5 !h-5" />
                </div>

                <div class="min-w-fit font-sf-pro font-semibold text-lg">
                  {{ item.title }}
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
